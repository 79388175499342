import React, {Fragment} from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Container } from 'constate';
import seapig, { REQUIRED, REQUIREDS } from 'seapig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { authEffects } from '../app/effects/auth';

import Layout from '../components/layout';

import { GridRow } from '../components/widgets/grid';

const ColumnHeader = ({title, price, period, sweetspot, register}) =>
  <div className="pt3 pb2 gradient-yellow bb br2 br--top b--gray">
    <h2 className="mt3">{title}</h2>

    <div className="flex flex-row justify-center">
      <h1 className="self-baseline f1 mr2">£{price}</h1>
      <span className="self-baseline f6 fw5">(+VAT)</span>
    </div>

    <h4 className="mb0">{period}</h4>

    <div className="h3 ml3 mr3 mt4 mb4">
      <p className="tc">{sweetspot}</p>
    </div>

    {/* <div className="mt3 mb3">
      <Link to='/trial' className="f5 ttu tracked grow hover-yellow no-underline br-pill ph3 pv2 mb2 dib white bg-mid-gray">
       Start Free
      </Link>
    </div> */}

  </div>

export const PlanBox = ({ children, best }) => {
  const {
    headerChildren,
    featureChildren,
  } = seapig(children, {
    header: REQUIRED,
    feature: REQUIREDS,
  });

  return (

    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-4 center-xs center-sm center-md pt3">
      <div className="ba br2 b--gray">
        <div>
          {headerChildren}
        </div>
        <div className="pt3 pb3 bg-white">
          {featureChildren}
        </div>
        <div className="h4 pt3 pb3 bt b--gray gradient-yellow">
          <h3>Best For</h3>
          <div className="ml3 mr3">{best}</div>
        </div>
      </div>
    </div>
  )
}

const Feature = ({children}) =>
  <div className="mt2 f4">
    {children}
  </div>

const CommonalityHeader = ({plans}) =>
  <>
    <div className="row h3 bb bb-1 b--black-10 flex flex-column items-center">

      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>

      {plans.map(({ name }, idx) =>
        <div key={idx} className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <div className="tc"><h3>{name}</h3></div>
        </div>
      )}

    </div>

    <div className="row h3 bb bb-1 b--black-10 flex flex-column items-center">

      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">Minimum cost per month</div>

      {plans.map(({ details: { cost: { min } } }, idx) =>
        <div key={idx} className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <div className="tc">{min}</div>
        </div>
      )}

    </div>
  </>

const Attribute = ({attribute}) =>
  <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
    {attribute === 'yes' && <div className="tc"><FontAwesomeIcon icon={['fad', 'check']} /></div>}
    {attribute !== 'yes' && <div className="tc">{attribute}</div>}
  </div>

const Commonality = ({description, attribute}) =>
  <div className="row h3 bb bb-1 b--black-10 flex flex-column items-center">

    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">{description}</div>

    <Attribute attribute={attribute}/>
    <Attribute attribute={attribute}/>

  </div>

const Pricing = (props) => {

  const { allPricing } = useStaticQuery(query);
  const { nodes : [pricing] } = allPricing;
  const { plans, commons } = pricing;

  return (
    <div className="pb5 graph-white">

      <Helmet title="Pricing | SwitchSmith">
        <link rel="canonical" href="https://switchsmith.com/pricing" />
      </Helmet>

      {/* <PageTitle title='Pricing Plans'/> */}

      <GridRow>
        <div className="row center-xs center-sm center-md center-lg">
          <h1>Pricing Plans</h1>
        </div>
      </GridRow>

      <GridRow>
        <div className="row center-xs center-sm center-md center-lg">
          <h3>Billed monthly.</h3>
          <h3 className="ml3-ns ml2">No minimum period.</h3>
          <h3 className="ml3-ns ml2">No hidden costs.</h3>
        </div>
      </GridRow>

      <GridRow>
        <div className="row center-sm center-md center-lg">

          {plans.map(({ name, details: { cost: { price, min }, period, numbers, features, sweetspot, best } }, idx) =>
            <Fragment key={idx}>

              <PlanBox key={idx} best={best}>
                <ColumnHeader header {...props} title={name} price={price} period={period} sweetspot={sweetspot} />
                <Feature feature>{numbers}</Feature>
                <Feature feature>{features}</Feature>
              </PlanBox>

              { idx < plans.length - 1 &&
                <div className="h3 col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
              }
            </Fragment>

          )}

          {/* <PlanBox >
            <ColumnHeader header {...props} title='Pay As You Go' price='2' period='Per active user day' />
            <Feature feature>3 UK numbers</Feature>
            <Feature feature>All SwitchSmith features</Feature>
          </PlanBox> */}

        </div>
      </GridRow>

      <GridRow>
        <div className="row center-xs center-sm center-md center-lg mt4 mb4">
          <h1>All Batteries Included</h1>
        </div>

        <CommonalityHeader plans={plans} />

        {commons.map((cm, idx) => <Commonality key={idx} {...cm}/> )}
      </GridRow>

    </div>
  );
}

const PricingPage = () => (
  <Layout>
    <Container
      context='primary'
      effects={authEffects}>
        {(props) => (
          <Pricing {...props} />
        )}
    </Container>
  </Layout>
)

export default PricingPage;

export const query = graphql`
  query {
    allPricing {
      nodes {
        commons {
          description
          attribute
        }
        plans {
          name
          details {
            cost {
              price
              min
            }
            period
            numbers
            features
            sweetspot
            best
          }
        }
      }
    }
  }
`
